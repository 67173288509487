









































import { Component, PropSync, Vue } from 'vue-property-decorator';
import AccessMoreStrategiesModerateHiringViewModel
  from '@/vue-app/view-models/allianz-dashboard/access-more-strategies/access-more-strategies-moderate-hiring-view-model';

@Component({
  name: 'AccessMoreStrategiesModerateHiringDialog',
  components: {
    StrategyComparisonPesosAndModerate: () => import('@/vue-app/components/strategy-moderate-wealth/hiring-flow/StrategyComparisonPesosAndModerate.vue'),
    StrategyInformationAndVideo: () => import('@/vue-app/components/strategy-moderate-wealth/hiring-flow/StrategyInformationAndVideo.vue'),
    StrategyHiring: () => import('@/vue-app/components/strategy-moderate-wealth/hiring-flow/StrategyHiring.vue'),
    StrategyTransfer: () => import('@/vue-app/components/strategy-moderate-wealth/hiring-flow/StrategyTransfer.vue'),
    StrategyRequestSent: () => import('@/vue-app/components/strategy-moderate-wealth/hiring-flow/StrategyRequestSent.vue'),
  },
})
export default class AccessMoreStrategiesModerateHiringDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  @PropSync('investorGoalId', { type: String, required: true })
  synced_investor_goal_id!: string;

  @PropSync('goalName', { type: String, required: true })
  synced_goal_name!: string;

  strategy_moderate_hiring_view_model = Vue.observable(
    new AccessMoreStrategiesModerateHiringViewModel(this),
  );

  closeModal() {
    this.synced_is_open = false;
    this.$emit('closeModal');
  }
}
